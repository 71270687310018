import React from 'react'
import ExploreCategory from '../../components/ExploreCategory/ExploreCategory'
import Footer from '../../components/Footer/Footer'
import Hero from '../../components/Hero/Hero'
import HomeBenifits from '../../components/HomeBenifits/HomeBenifits'
import HomeTestimonial from '../../components/HomeTestimonial/HomeTestimonial'
import PopularProducts from '../../components/PopularProducts/PopularProducts'
import SpecialPackage from '../../components/SpecialPackage/SpecialPackage'
import OwnPackage from '../../components/OwnPackage/OwnPackage'


function Home() {
  return (
    <>
      <Hero />
      <ExploreCategory />
      <PopularProducts />
      <SpecialPackage />
      <OwnPackage />
      <HomeBenifits />
      <HomeTestimonial />
      <Footer />
    </>
  )
}

export default Home