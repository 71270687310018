import React from 'react'
import "./Hero.css"

function Hero() {
  return (
    <div className='heroContainer'>
        <div className="nav_padding"></div>
        <div className="heroContainer__text">
            <p className="heroContainer--heading">
                {/* Marotia U+2019 s Eco-friendly Bagasse Collection */}
                Marotia's Eco-friendly Bagasse Collection
            </p>
            <p className="heroContainer--subHeading font_open_sans">
              Explore different categories. Find the best deals.
            </p>
            <button className='heroContainer--actionButton font_white'>Shop Now</button>
        </div>
    </div>
  )
}

export default Hero