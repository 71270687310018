import React from 'react'
import "./HomeBenifits.css"
import payment_method from "../../Assets/payment_method.png"
import exchange from "../../Assets/exchange.png"
import customer_care from "../../Assets/customer_care.png"

function HomeBenifits() {
  return (
    <div className='homeBenifits'>
        <p className="homeBenifits__heading font_primary_one font_playfair font_bold font_section_heading">Benefits for your expediency</p>        

        <div className="homeBenifits__content font_primary_one">

            <div className="homeBenifits__content--data">
                <div className="homeBenifits__content--data--image_container homeBenifits--container-1">
                    <img src={payment_method} alt="" className='homeBenifits__content--data--image homeBenifits__image-1'/>
                </div>
                <p className="homeBenifits__content--data--heading font_playfair font_24 font_bold">Payment Method</p>
                <p className="homeBenifits__content--data--text font_open_sans font_18">We offer flexible payment options, to make easier.</p>
            </div>

            <div className="homeBenifits__content--data">
                <div className="homeBenifits__content--data--image_container homeBenifits--container-2">
                    <img src={exchange} alt="" className='homeBenifits__content--data--image homeBenifits__image-1'/>
                </div>
                <p className="homeBenifits__content--data--heading font_playfair font_24 font_bold">Eco-Friendly</p>
                <p className="homeBenifits__content--data--text font_open_sans font_18">
                You can return it to mother earth within 30 days.
                </p>
            </div>

            <div className="homeBenifits__content--data">
                <div className="homeBenifits__content--data--image_container homeBenifits--container-3">
                    <img src={customer_care} alt="" className='homeBenifits__content--data--image homeBenifits__image-1'/>
                </div>
                <p className="homeBenifits__content--data--heading font_playfair font_24 font_bold">Customer Support</p>
                <p className="homeBenifits__content--data--text font_open_sans font_18">
                    Our customer support is 24/7.
                </p>
            </div>

        </div>
    </div>
  )
}

export default HomeBenifits