import React from 'react';
import "./ExploreCategory.css";
import search from "../../Assets/search_grey.png";
import plateImage from "../../Assets/plate1.png";
import bowlImage from "../../Assets/bowl1.png";


function ExploreCategory() {
  return (
    <div className='categoryExplore_container'>
        {/* <div className="nav_padding"></div> */}
        <div className="categoryExplore__content">
            <div className="categoryExplore__heading font_color_primary font_playfair font_section_heading">
                Explore by Category
            </div>
            <div className="categoryExplore--section">
                <div className="section_search">
                    <div className="categoryExplore__search">
                        <input type="text" className='section_search--input' placeholder='search'/>
                        <img src={search} alt="search" className='categoryExplore__searchIcon'/>
                    </div>
                    <div className="section_search--options font_open_sans">
                        <div className="section_search--option--item">Plates</div>
                        <div className="section_search--option--item">Bowls</div>
                    </div>
                </div>

                <div className="section__product">
                    <div className="categoryExplore__item">
                        <img src={plateImage} alt="plate Image" className='categoryExplore__item--image'/>
                        <div className="categoryExplore__item__hover">
                            <p className="categoryExplore__item__heading">Plates</p>
                            <button className='categoryExplore__item__button font_open_sans'>Explore</button>
                        </div>
                    </div>
                    <div className="categoryExplore__item">
                        <img src={bowlImage} alt="plate Image" className='categoryExplore__item--image'/>
                        <div className="categoryExplore__item__hover">
                            <p className="categoryExplore__item__heading">Bowls</p>
                            <button className='categoryExplore__item__button font_open_sans'>Explore</button>
                        </div>
                    </div>
                </div>
            </div>
       </div>
    </div>
  )
}

export default ExploreCategory