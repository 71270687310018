import React from 'react'
import "./Footer.css"
import newsletterImage from "../../Assets/newsletter.png"
import logoImage from "../../Assets/nav_Logo.png"
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

function Footer() {
    return (
        <div className='footer'>
            <div className="footer__newsletter">
                <div className="footer__newsletter--left">
                    <img src={newsletterImage} alt="mail-box" className='footer__newsletter--image' />
                </div>
                <div className="footer__newsletter--right">
                    <p className="footer__newsletteer--right--heading font_playfair">Join Our</p>
                    <p className="footer__newsletteer--right--heading font_playfair font_bold">Newsletter</p>
                    <p className="footer__newsletteer--right--text font_open_sans font_20">Receive exclusive deals, discounts and many offers.</p>
                    <input type="text" placeholder='Enter your email' className='footer__newsletter--input' />
                    <button className='footer__newsletter--button'>Subscribe</button>
                </div>
            </div>

            <div className="footer__map">
                <div className="footer__map--content">
                    <div className="footer__map--left">
                        <div className="footer__map--heading">
                            <img src={logoImage} alt="logo" className='footer__map--heading--logo' />
                            <p className="footer__map--heading--text--address font_open_sans font_18 font_bold font_primary_one">Address</p>
                            <p className="footer__map--heading--text font_open_sans font_primary_one font_18">
                                11, Clive Row, Room No.1, 4th floor, Kolkata, West Bengal - 700 001,
                            </p>
                            <p className="footer__map--heading--text font_open_sans font_primary_one font_18">
                                India
                            </p>
                        </div>
                    </div>

                    {/* all links are here */}
                    <div className="footer__map--links font_open_sans font_primary_one font_18">
                        <div className="footer__map--link">
                            <p className="footer__map--link-heading font_bold">My Account</p>
                            <p className="footer__map--link-text">Sign in</p>
                            <p className="footer__map--link-text">Register</p>
                            <p className="footer__map--link-text">Order Status</p>
                        </div>
                        <div className="footer__map--link">
                            <p className="footer__map--link-heading font_bold">Help</p>
                            <p className="footer__map--link-text">Shipping</p>
                            <p className="footer__map--link-text">Returns</p>
                            <p className="footer__map--link-text">Sizing</p>
                        </div>
                        <div className="footer__map--link">
                            <p className="footer__map--link-heading font_bold">Shop</p>
                            <p className="footer__map--link-text">Plates</p>
                            <p className="footer__map--link-text">Bowls</p>
                        </div>
                        <div className="footer__map--link">
                            <p className="footer__map--link-heading font_bold">Legal Stuff</p>
                            <p className="footer__map--link-text">Shipping & Delivery</p>
                            <p className="footer__map--link-text">Terms & Conditions</p>
                            <p className="footer__map--link-text">Privacy & Policy</p>
                        </div>
                    </div>
                    <div className="footer__map--right"></div>
                </div>
                <div className="footer__map--copyright">
                    <p className="footer__map--copyright--text font_18 font_open_sans">
                        Copyright ©2023 Marotia Bioware. All Rights Reserved
                    </p>
                    <div className="footer__map--socials">
                        <FacebookIcon id='footer__map--socials--icons'/>
                        <InstagramIcon id='footer__map--socials--icons'/>
                        <LinkedInIcon id='footer__map--socials--icons'/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer