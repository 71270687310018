import React from 'react'
import "./Navbar.css"
import logo from "../../Assets/nav_Logo.png"
import search_icon from "../../Assets/search.png"
import cart_icon from "../../Assets/nav_cart.png"
import account_icon from "../../Assets/nav_account.png"
import { useState, useEffect } from 'react'

function Navbar() {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [navMobileOpen, setNavMobileOpen] = useState(false);

    const handleScroll = () => {
        const position = window.pageYOffset;
        // console.log(position)
        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
    
        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
      }, []);

      const handleBurgerClick = () => {
        console.log("handeling on click")
        setNavMobileOpen(!navMobileOpen);
      }

  return (
    <div className={`navbar`} id={`${(scrollPosition >= 160 || navMobileOpen) ? "navbar__white" : ""}`}>
        <div className="navbar__burger" onClick={handleBurgerClick} >
            <div className="navbar__burger--line"></div>
            <div className="navbar__burger--line"></div>
            <div className="navbar__burger--line"></div>
        </div>
        <div className="navbar__left">
            <img src={logo} alt="logo" className='navbar__left--logo'/>
        </div>
        <div className="navbar__middle" id={`${navMobileOpen ? "navMobileOpen" : ""}`}>
            <ul className="navbar__middle--items font_20 font_playfair">
                <li className="navbar__middle--item">Home</li>
                <li className="navbar__middle--item">Products</li>
                <li className="navbar__middle--item">Categories</li>
                <li className="navbar__middle--item">About</li>
                <li className="navbar__middle--item">Contact Us</li>
            </ul>
        </div>
        <div className="navbar__right">
            <ul className="navbar__right--items">
                <li className="navbar__right--item">
                    <img src={search_icon} alt="search" className='navbar__right--icon'/>
                </li>
                <li className="navbar__right--item">
                    <img src={cart_icon} alt="search" className='navbar__right--icon'/>
                </li>
                <li className="navbar__right--item" id='navbar__accountIcon'>
                    <img src={account_icon} alt="search" className='navbar__right--icon'/>
                </li>
            </ul>
        </div>
    </div>
  )
}

export default Navbar