import React, { useEffect, useState } from 'react'
import "./ProductCard.css"
// import cardImage from "../../Assets/product1.png";

function ProductCard({ cardBg, itemDetails }) {
    const [cardBackgroundValue, setcardBackgroundValue] = useState("")

    useEffect(() => {
        let _temp_num = cardBg
        if(cardBg > 4) {
            _temp_num = cardBg - 4;
        } else {
            _temp_num = cardBg
        }
        // setcardBackgroundValue(_temp_num);
        if(_temp_num === 1) {
            setcardBackgroundValue("color_one")
        } else if(_temp_num === 2) {
            setcardBackgroundValue("color_two")
        } else if(_temp_num === 3) {
            setcardBackgroundValue("color_three")
        }else if(_temp_num === 4) {
            setcardBackgroundValue("color_four")
        }
    }, [])
    

  return (
    <div className='productCard' id={`${cardBackgroundValue}`}>
        <div className="productCard__imageContainer">
            <img src={itemDetails.image} alt="item Image" className='productCard__image'/>
        </div>
        <div className="productCard__info font_open_sans">
            <div className="productCard__info--content font_bold">
                <p className='productCard__info--name--text font_24'>
                    {itemDetails.name}
                </p>
            </div>
            <div className="productCard__info--content">
                <p className='productCard__info--size--text font_18'>
                    {itemDetails.size} Inch
                </p>
            </div>
            <div className="productCard__info--content font_bold">
                <p className='productCard__info--price--text font_24'>
                    &#x20b9; {itemDetails.price}
                </p>
            </div>
        </div>
    </div>
  )
}

export default ProductCard