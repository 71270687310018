import React from 'react';
import "./SpecialPackage.css";
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import StarRoundedIcon from '@mui/icons-material/StarRounded';

import ReactStars from "react-rating-stars-component";
import image1 from "../../Assets/image1.png";
import ratingStar from "../../Assets/ratingStar.png";
import { useState } from 'react';

// images imported
import roundPlate_12 from "../../Assets/product1.png";
import roundPlate_10 from "../../Assets/round_plate_10_inch.png";
import roundPlate_7 from "../../Assets/round_plate_7_inch.png";
import squarePlate_7 from "../../Assets/square_plate_6_inch.png";
import { WidthFull } from '@mui/icons-material';

function SpecialPackage() {

  const [suggestedItems, setSuggestedItems] = useState([
    {
      name: "10 inch round plate",
      rating: 5,
      price: 15,
      image: roundPlate_10,
    },
    {
      name: "7 inch square plate",
      rating: 5,
      price: 15,
      image: squarePlate_7,
    },
    // {
    //   name: "7 inch round plate",
    //   rating: 5,
    //   price: 15
    // },
  ])

  const suggestionCard = (item, i) => {

    let colorIndex = '';

    if (i + 1 === 1) {
      colorIndex = "color_one"
    } else if (i + 1 === 2) {
      colorIndex = "color_two"
    } else if (i + 1 === 3) {
      colorIndex = "color_three"
    } else if (i + 1 === 4) {
      colorIndex = "color_four"
    }

    return (
      <div className="specialPackage__suggestion--container">
        <div className="specialPackage__suggestion--left" id={colorIndex}>
          <img src={item.image} alt="" className="specialPackage__sggestion--left--img" />
        </div>

        <div className="specialPackage__suggestion--right">
          <div className="specialPackage__suggestion--right--details">
            <p className="specialPackage__suggestion--name font_20 font_open_sans font_primary_one">{item.name}</p>
            {/* TODO: add rating here */}
            <button className="specialPackage__suggestion--seeMore font_primary_one">See Details</button>
          </div>
        </div>
        <div className="specialPackage__suggestion--left--price">
          <p className="specialPackage__suggestion--name font_20 font_open_sans font_primary_one">&#x20b9; {item.price}</p>
        </div>

      </div>
    )
  }

  const renderRatings = () => {
    return (
      <div className='specialPackageRatingStar'>
        <img src={ratingStar} alt="stars" className='specialPackageRatingStar--star'/>
        <img src={ratingStar} alt="stars" className='specialPackageRatingStar--star'/>
        <img src={ratingStar} alt="stars" className='specialPackageRatingStar--star'/>
        <img src={ratingStar} alt="stars" className='specialPackageRatingStar--star'/>
        <img src={ratingStar} alt="stars" className='specialPackageRatingStar--star'/>
      </div>
    )
  }


  return (
    <div className='specialPackage'>
      <p className="specialPackage__heading font_primary_one font_section_heading">Special Package</p>
      <div className="specialPackage__content">

        <div className="specialPackage__content--left">
          <div className="specialPackage__content--left--image">
            <img src={image1} alt="" className="specialPackage__content--left--img" />
          </div>
          <div className="specialPackage__content--details">
            <div className="specialPackage__content--details-left">
              <p className="specialPackage__content__Name font_24 font_open_sans font_primary_one">{"Round Plate (12 inch)"}</p>

              {renderRatings()}

              <p className="specialPackage__content__price font_20 font_bold font_open_sans font_primary_one">&#x20b9; 15</p>
            </div>
            <div className="specialPackage__content--Cart_button_container">
              <button className="specialPackage__content--Cart_button font_20 font_open_sans">
                Add to cart
                <ShoppingCartOutlinedIcon id="specialPackage__content--Cart_button--icon" />
              </button>
            </div>
          </div>
        </div>

        <div className="specialPackage__content--right">

          <div className="specialPackage__content--right--description">
            <p className="specialPackage__content--description--heading font_open_sans font_24 font_primary_one font_bold">Description</p>

            <ul className="specialPackage__content--description--listItems font_open_sans font_18 font_primary_one"><br />
              <li className="specialPackage__content--description--listItem">Ultra Strong And Leak Proof.</li>
              <li className="specialPackage__content--description--listItem">100% Eco-Friendly, Biodegradable and Compostable.</li>
              <li className="specialPackage__content--description--listItem">100% Natural, Food Grade and Non-Toxic.</li>
              <li className="specialPackage__content--description--listItem">Zero Plastic.</li>
              <li className="specialPackage__content--description--listItem">100% Microwave Safe.</li>
              <li className="specialPackage__content--description--listItem">100% Microwave Safe.</li>
              <li className="specialPackage__content--description--listItem">100% Refrigerator Safe.</li>
            </ul>
            <button className="specialPackage__content--seeMore font_18 font_open_sans font_primary_one">
              See More
              <KeyboardArrowDownRoundedIcon />
            </button>
          </div>

          <div className="specialPackage__suggestion">
            {suggestedItems.map((item, i) => {
              return suggestionCard(item, i)
            })}
          </div>

        </div>

      </div>
    </div>
  )
}

export default SpecialPackage