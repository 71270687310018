import React from 'react'
import "./HomeTestimonial.css"
import quotes from "../../Assets/Quote.png"
import testimonialImage from "../../Assets/testimonial1.png"
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';

function HomeTestimonial() {


    const renderTestimonial = () => {
        return (
            <div className="homeTestimonial__content--testimonial">
                <div className="homeTestimonial__content--testimonial--left">
                    {/* the testimonial image will come here */}
                    <img src={testimonialImage} alt="quotes" className='homeTestimonial__content--testimonial--left--image' />
                    <img src={quotes} alt="quotes" className='homeTestimonial__content--testimonial--left--quote' />
                </div>
                <div className="homeTestimonial__content--testimonial--right font_primary_one">
                    <p className="homeTestimonial_text font_open_sans">
                        “My experience with Mark is a complete sucess, from customer service, wide range of products, clean store, purchasing experience, the newsletter.Thank you.”
                    </p>
                    <p className="homeTestimonial_name font_open_sans font_bold">
                        Ralph Furtado
                    </p>
                    <p className="homeTestimonial_position font_open_sans">
                        CEO of Flatcom
                    </p>

                </div>
            </div>
        )
    }

    return (
        <div className='homeTestimonial'>
            <p className="homeTestimonial__heading font_playfair font_primary_one font_section_heading">Testimonials</p>
            <p className="homeTestimonial__subheading font_open_sans font_primary_one font_36">Over 15,000 happy customers</p>

            <div className="homeTestimonial__content">
                <div className="homeTestimonial__testimonialContent">

                    {/* <div className="homeTestimonial__content--testimonial">
                        <div className="homeTestimonial__content--testimonial--left">
                            
                            <img src={quotes} alt="quotes" className='homeTestimonial__content--testimonial--left--quote' />
                        </div>
                        <div className="homeTestimonial__content--testimonial--right font_primary_one">
                            <p className="homeTestimonial_text font_open_sans">
                                “My experience with Mark is a complete sucess, from customer service, wide range of products, clean store, purchasing experience, the newsletter.Thank you.”
                            </p>
                            <p className="homeTestimonial_name font_open_sans font_bold">
                                Leona Paul
                            </p>
                            <p className="homeTestimonial_position font_open_sans">
                                CEO of Flatcom
                            </p>

                        </div>
                    </div> */}
                    {renderTestimonial()}
                    {/* {renderTestimonial()} */}

                    {/* navigation buttons */}
                    <div className="homeTestimonial__content--navigation">
                        <button className="homeTestimonial__content--navigation--previous">
                            <ArrowBackRoundedIcon />
                        </button>
                        <button className="homeTestimonial__content--navigation--next">
                            <ArrowForwardRoundedIcon />
                        </button>
                    </div>

                </div>
            </div>

        </div>
    )
}

export default HomeTestimonial