import React, { useEffect, useRef, useState } from 'react'
import ProductCard from '../productCard/ProductCard'
import "./PopularProducts.css"
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';

// images imported
import roundPlate_12 from "../../Assets/product1.png";
import roundPlate_10 from "../../Assets/round_plate_10_inch.png";
import roundPlate_7 from "../../Assets/round_plate_7_inch.png";
import squarePlate_6 from "../../Assets/square_plate_6_inch.png";

function PopularProducts() {

  const [productsArray, setProductsArray] = useState([
    {
      name: "Round Plates",
      size: "12",
      price: 15,
      image: roundPlate_12
    },
    {
      name: "Round Plates",
      size: "10",
      price: 15,
      image: roundPlate_10
    },
    {
      name: "Square Plates",
      size: "6",
      price: 15,
      image: squarePlate_6
    },
    {
      name: "Round Plates",
      size: "7",
      price: 15,
      image: roundPlate_7
    },
  ])

  const [sliderItemLength, setSliderItemLength] = useState(0)
  const [sliderCurrentValue, setSliderCurrentValue] = useState(0)
  const [sliderCurrentValueArr, setSliderCurrentValueArr] = useState([])
  const sliderElement = useRef(null)

  const [touchStart, setTouchStart] = useState(null)
  const [touchEnd, setTouchEnd] = useState(null)

  const dragConstant = 100;

  useEffect(() => {

    setNavigationScrollbar();

  }, [sliderCurrentValueArr.length, sliderItemLength])


  const scrollEventTest = (e) => {
    console.log("scroll event is: ", e.currentTarget);
  }

  const setNavigationScrollbar = () => {
    const slider = sliderElement.current;
    const itemsLength = sliderElement.current.children.length;
    const itemsPerScreen = getComputedStyle(slider).getPropertyValue("--items-per-screen");

    // calculating the number of divs to be rendered
    const numberOfDivs = Math.ceil(itemsLength / itemsPerScreen)
    let temp_arr = [];
    for (let i = 0; i < numberOfDivs; i++) {
      temp_arr.push(i + 1);
    }

    setSliderCurrentValueArr([...temp_arr])
  }

  // function to handle navigation
  const handleNextClick = (e) => {
    const slider = e.target.closest(".popularProducts__navigation--button").closest(".popularProducts__navigation").closest(".popularProducts").querySelector(".popularProducts--cards")

    const sliderIndex = parseInt(
      getComputedStyle(slider).getPropertyValue("--slider-index")
    )

    if (sliderIndex >= (sliderCurrentValueArr.length - 1)) {
      return null
    }
    // setting the style
    slider.style.setProperty("--slider-index", sliderIndex + 1);
    // console.log(sliderIndex);
    setSliderCurrentValue(sliderIndex + 1)
  }

  const handlePreviousClick = (e) => {
    const slider = e.target.closest(".popularProducts__navigation--button").closest(".popularProducts__navigation").closest(".popularProducts").querySelector(".popularProducts--cards")

    const sliderIndex = parseInt(
      getComputedStyle(slider).getPropertyValue("--slider-index")
    )

    if (sliderIndex < 1) {
      return null
    }

    // setting the style
    slider.style.setProperty("--slider-index", sliderIndex - 1);

    setSliderCurrentValue(sliderIndex - 1)
  }


// functions to handle swipe events

  const onTouchStart = (e) => {
    // console.log("touch start triggered: ", e.targetTouches[0].clientX)
    setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX)
  }

  const onTouchMove = (e) => {
    // console.log("touch move triggered")
    setTouchEnd(e.targetTouches[0].clientX)
  }

  const onTouchEnd = (e) => {
    if (!touchStart || !touchEnd) return
    const distance = touchStart - touchEnd
    // console.log("event: ", e.target.closest(".popularProducts--card").closest(".popularProducts--cards"));
    const slider = e.target.closest(".popularProducts--card").closest(".popularProducts--cards")

    const minSwipeDistance = 50

    const isLeftSwipe = distance > minSwipeDistance
    const isRightSwipe = distance < -minSwipeDistance
    // if (isLeftSwipe || isRightSwipe) console.log('swipe', isLeftSwipe ? 'left' : 'right')

    if (isLeftSwipe || isRightSwipe) {
      if (isLeftSwipe) {
        const sliderIndex = parseInt(
          getComputedStyle(slider).getPropertyValue("--slider-index")
        )

        if (sliderIndex >= (sliderCurrentValueArr.length - 1)) {
          return null
        }
        // setting the style
        slider.style.setProperty("--slider-index", sliderIndex + 1);
        // console.log(sliderIndex);
        setSliderCurrentValue(sliderIndex + 1)
      } else if (isRightSwipe) {
        const sliderIndex = parseInt(
          getComputedStyle(slider).getPropertyValue("--slider-index")
        )

        if (sliderIndex < 1) {
          return null
        }

        // setting the style
        slider.style.setProperty("--slider-index", sliderIndex - 1);

        setSliderCurrentValue(sliderIndex - 1)
      }
    }
  }



  // testing the pointer events
  const handlePointerDown = (e) => {
    console.log("touch start is: ", e)
    if(e.pointerType !== 'mouse') return
    setTouchEnd(null)
    setTouchStart(e.clientX)
  }

  const handlePointerMove = (e) => {
    if(touchStart === null) return
    setTouchEnd(e.clientX)
    // console.log("hover in move: ", e.clientX);
  }

  const handlePointerUp = (e) => {
    const minMovement = 100;
    console.log("reached here!")
    
    if (!touchStart || !touchEnd) return

    if(e.pointerType !== 'mouse') return

    const _movement = touchStart - touchEnd; 
    const slider = e.target.closest(".popularProducts--card").closest(".popularProducts--cards")
    console.log(slider)
    const isLeftSwipe = _movement > minMovement
    const isRightSwipe = _movement < -minMovement
    console.log("swipe is: ", isLeftSwipe, isLeftSwipe)
    // console.log("ovement is: ", _movement)
    if(isLeftSwipe || isRightSwipe) {
      if(_movement !== 0) console.log(`movement is towards ${isRightSwipe ? isRightSwipe : isLeftSwipe}`)

      // for the right movement 
      if (isLeftSwipe) {
        const sliderIndex = parseInt(
          getComputedStyle(slider).getPropertyValue("--slider-index")
        )

        if (sliderIndex >= (sliderCurrentValueArr.length - 1)) {
          return null
        }
        // setting the style
        slider.style.setProperty("--slider-index", sliderIndex + 1);
        // console.log(sliderIndex);
        setSliderCurrentValue(sliderIndex + 1)
      } else if (isRightSwipe) {
        console.log("right swipe bro")
        const sliderIndex = parseInt(
          getComputedStyle(slider).getPropertyValue("--slider-index")
        )

        if (sliderIndex < 1) {
          return null
        }

        // setting the style
        slider.style.setProperty("--slider-index", sliderIndex - 1);

        setSliderCurrentValue(sliderIndex - 1)
      }
    }
    setTouchStart(null);
    setTouchEnd(null)
  }


  return (
    <div className='popularProducts'>
      <p className="popularProducts__heading font_section_heading">
        Popular Products
      </p>
      {/* <div className="popularProducts--cards snap-inline"> */}
      <div
        className="popularProducts--cards"
        ref={sliderElement}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}

        onPointerDown={handlePointerDown}
        onPointerMove={handlePointerMove}
        onPointerUp={handlePointerUp}
        // draggable
        // onDrag={onDrag}
      >
        <div className="popularProducts--card">
          {/* <ProductCard cardBg={1} cardImage={roundPlate_12}/> */}
        </div>
        {productsArray.map((item, index) => {
          return (
            <div className="popularProducts--card">
              <ProductCard cardBg={index + 1} itemDetails={item} />
            </div>
          )
        })
        }
        {/* <div className="popularProducts--card">
            <ProductCard cardBg={1} cardImage={roundPlate_12}/>
          </div>
          <div className="popularProducts--card">
            <ProductCard cardBg={2} cardImage={roundPlate_10}/>
          </div>
          <div className="popularProducts--card">
            <ProductCard cardBg={3} cardImage={squarePlate_7}/>
          </div>
          <div className="popularProducts--card">
            <ProductCard cardBg={4} cardImage={roundPlate_7}/>
          </div> */}
      </div>

      <div className="popularProducts__scrollbar">
        {/* {renderProgressBar()} */}
        {sliderCurrentValueArr.map((element, i) => {
          return <div className={`popularProducts__scrollbar--item ${sliderCurrentValue === element - 1 ? "active" : ""}`}></div>
        })}
      </div>

      {/* corossol control */}
      <div className="popularProducts__navigation">
        <button className="popularProducts__navigation--button popularProducts__navigation--button--left" onClick={handlePreviousClick}>
          <ArrowBackRoundedIcon />
        </button>
        <button className="popularProducts__navigation--button popularProducts__navigation--button--right" onClick={handleNextClick}>
          <ArrowForwardRoundedIcon />
        </button>
      </div>

      {/* card action section */}
      <div className="popularProducts__action">
        <button className="popularProducts__button font_open_sans font_20">
          Explore all items
          <ArrowForwardRoundedIcon id="popularProducts__button--icon"/>
        </button>
      </div>
    </div>
  )
}

export default PopularProducts