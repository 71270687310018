import React, { useState, useRef, useEffect } from 'react';
import "./OwnPackage.css";
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';

// all the images
import image from "../../Assets/plateImage.png";
import plates_and_bowls from "../../Assets/plates_and_bowls.png";
import cups_image from "../../Assets/cups_image.png";
import plates_and_glasses from "../../Assets/plates_and_glasses.png";


function OwnPackage() {

    

    const [sliderItemLength, setSliderItemLength] = useState(0)
  const [sliderCurrentValue, setSliderCurrentValue] = useState(1)
  const [sliderCurrentValueArr, setSliderCurrentValueArr] = useState([])
  const sliderElement = useRef(null)

  const [touchStart, setTouchStart] = useState(null)
  const [touchEnd, setTouchEnd] = useState(null)

  useEffect(() => {

    setNavigationScrollbar();
  }, [sliderCurrentValueArr.length, sliderItemLength, sliderCurrentValue])

  const setNavigationScrollbar = () => {
    const slider = sliderElement.current;
    const itemsLength = sliderElement.current.children.length;
    const itemsPerScreen = getComputedStyle(slider).getPropertyValue("--items-per-screen");

    // calculating the number of divs to be rendered
    const numberOfDivs = Math.ceil(itemsLength /itemsPerScreen)
    let temp_arr = [];
    for(let i = 0; i < numberOfDivs; i++) {
      temp_arr.push(i+1);
    }

    setSliderCurrentValueArr([...temp_arr])
  }

    // function to handle navigation
  const handleNextClick = (e) => {
    const slider = e.target
        .closest(".ownPackage--navigaion--next")
        .closest(".ownPackage--navigation")
        .closest(".ownPackage__info")
        .closest(".ownPackage")
        .querySelector(".ownPackage__carousel")

        console.log(slider)


    const sliderIndex = parseInt(
      getComputedStyle(slider).getPropertyValue("--slider-index")
    )
    
    if(sliderIndex >= (sliderCurrentValueArr.length - 1)) {
      return null
    }

    // setting the style
    slider.style.setProperty("--slider-index", sliderIndex + 1);
    
    setSliderCurrentValue(sliderIndex + 1)
  }

  const handlePreviousClick = (e) => {
    const slider = e.target
        .closest(".ownPackage--navigaion--previous")
        .closest(".ownPackage--navigation")
        .closest(".ownPackage__info")
        .closest(".ownPackage")
        .querySelector(".ownPackage__carousel")

    // console.log(slider)
    const sliderIndex = parseInt(
      getComputedStyle(slider).getPropertyValue("--slider-index")
    )
    
    if(sliderIndex < 1) {
      return null
    }

    // setting the style
    slider.style.setProperty("--slider-index", sliderIndex - 1);

    setSliderCurrentValue(sliderIndex - 1)
  }

  // touch functions
    const onTouchStart = (e) => {
    console.log("touch start triggered: ", e.targetTouches[0].clientX)
    setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX)
  }

  const onTouchMove = (e) => {
    // console.log("touch move triggered")
    setTouchEnd(e.targetTouches[0].clientX)
  }

  const onTouchEnd = (e) => {
    if (!touchStart || !touchEnd) return
    const distance = touchStart - touchEnd
    // console.log("event: ", e.target.closest(".popularProducts--card").closest(".popularProducts--cards"));
    const slider = e.target.closest(".ownPackage--cardContainer").closest(".ownPackage__carousel")

    const minSwipeDistance = 50

    const isLeftSwipe = distance > minSwipeDistance
    const isRightSwipe = distance < -minSwipeDistance
    if (isLeftSwipe || isRightSwipe) console.log('swipe', isLeftSwipe ? 'left' : 'right')

    if (isLeftSwipe || isRightSwipe) {
      if (isLeftSwipe) {
        const sliderIndex = parseInt(
          getComputedStyle(slider).getPropertyValue("--slider-index")
        )

        if (sliderIndex >= (sliderCurrentValueArr.length - 1)) {
          return null
        }
        // setting the style
        slider.style.setProperty("--slider-index", sliderIndex + 1);
        // console.log(sliderIndex);
        setSliderCurrentValue(sliderIndex + 1)
      } else if (isRightSwipe) {
        const sliderIndex = parseInt(
          getComputedStyle(slider).getPropertyValue("--slider-index")
        )

        if (sliderIndex < 1) {
          return null
        }

        // setting the style
        slider.style.setProperty("--slider-index", sliderIndex - 1);

        setSliderCurrentValue(sliderIndex - 1)
      }
    }
  }

    const renderCard = (cardImage) => {
        return (
            <div className="ownPackageCard">
                <img src={cardImage} alt="" className="ownPackageCardImage" />
                <button className="ownPackageCard--button font_18 font_primary_one">Explore All Options</button>
            </div>
        )
    }


  return (
    <div className='ownPackage'>
        <div className="ownPackage__info--container">
            <div className="ownPackage__info">
                <p className="ownPackage__info--heading font_26 font font_white font_playfair font_bold">Make Your Own Package</p>
                <p className="ownPackage__info--subHeading font_open_sans font_white font_20">Made in our factory</p>
                <div className="ownPackage__scrollBar">
                    {/* {renderProgressBar()} */}
                    {sliderCurrentValueArr.map((element, i) => {
                        return <div className={`ownPackage__scrollBar--item ${sliderCurrentValue === element-1 ? "ownPackage--active" : ""}`}></div>
                    })}
                </div>
                <div className="ownPackage--navigation">
                    <button className="ownPackage--navigaion--previous" onClick={handlePreviousClick}>
                        <ArrowBackRoundedIcon />
                    </button>
                    <button className="ownPackage--navigaion--next" onClick={handleNextClick}>
                        <ArrowForwardRoundedIcon />
                    </button>
                </div>
            </div>
        </div>

        <div
          className="ownPackage__carousel"
          ref={sliderElement}
          onTouchStart={onTouchStart}
          onTouchMove={onTouchMove}
          onTouchEnd={onTouchEnd}
        >
            <div className="ownPackage--cardContainer">
                {renderCard(image)}
            </div>
            <div className="ownPackage--cardContainer">
                {renderCard(cups_image)}
            </div>
            <div className="ownPackage--cardContainer">
                {renderCard(plates_and_glasses)}
            </div>
            {/* <div className="ownPackage--cardContainer">
                {renderCard(plates_and_glasses)}
            </div> */}
            <div className="ownPackage--cardContainer">
                {renderCard(plates_and_bowls)}
            </div>
            {/* <div className="ownPackage--cardContainer">
                {renderCard()}
            </div>
            <div className="ownPackage--cardContainer">
                {renderCard()}
            </div> */}
        </div>
    </div>
  )
}

export default OwnPackage